import React from 'react';
import '../App.css';
import './HeroSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from './Button';
import backgroundImage from '../images/Rectangle3.png';
import backgroundImage2 from '../images/Rectangle1.png';

function HeroSection() {
  return (
    <div className='hero-container'>
        <div className='h1-section-wrapper'>
          <h1>Niezbędni w trakcie twojej podróży</h1>
          <p>Kliknij poniżej i rezerwuj już teraz!</p>
          <div className='hero-btns'>
              <a href="#reservation" className="reservation-btn">
                REZERWUJ
              </a>
          </div>
        </div>
        <div className='hero-tiles-wrapper'>
            <div className='hero-tile'>
                <div className='hero-icon-circle'>
                    <FontAwesomeIcon icon='fa-solid fa-van-shuttle'/>
                </div>
                <div className='hero-tile-text'>
                    <h2>Bezpłatny Transfer</h2>
                    <p>Kilkuminutowy przejazd w dwie strony w klimatyzowanych samochodach</p>
                </div>
            </div>
            <div className='hero-tile'>
                <div className='hero-icon-circle'>
                    <FontAwesomeIcon icon='fa-solid fa-video'/>
                </div>
                <div className='hero-tile-text'>
                    <h2>Monitoring</h2>
                    <p>Bezpieczny ogrodzony i monitorowany parking</p>
                </div>
            </div>
            <div className='hero-tile'>
                <div className='hero-icon-circle'>
                    <FontAwesomeIcon icon='fa-solid fa-shield-halved'/>
                </div>
                <div className='hero-tile-text'>
                    <h2>Dozór</h2>
                    <p>Parking dozorowany 24/7</p>
                </div>
            </div>
        </div>
    </div>
  );
}

export default HeroSection;
